import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { GenerateBracketComponent } from './generate-bracket/generate-bracket.component';
import { Mail } from './service2';
import { Http } from '@angular/http';
import { globals } from './shared/globals';
import { UrlService, HttpService, AuthGuard } from './shared';
import { Bracketprintapi } from './services';
// import { AuthService } from './services';
import { HttpClientModule, HttpClient } from '@angular/common/http';

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		AboutusComponent,
		GenerateBracketComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		HttpModule
	],
	providers: [Mail, HttpClient, globals, HttpService, UrlService, Bracketprintapi],
	bootstrap: [AppComponent]
})
export class AppModule { }
