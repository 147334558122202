import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
	currentroute: any;
	constructor(private router: Router) {
		let self = this;
//		console.log(this.router.url, "Current URL");
		self.currentroute = this.router.url;
	}
	ngOnInit() {
		$('ul li').click(function () {
			console.log(0);
			$('li').removeClass("menu-active");
		});
	}
}
