import { Component, OnInit } from '@angular/core';
import { Mail } from '../service2';
import { validateConfig } from '@angular/router/src/config';
declare var $;
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

	frm: any = { name: <any>null, email: <any>null, subject: <any>null, message: <any>null };
	constructor(private mail: Mail) { }

	ngOnInit() {
		$('.nav-menu li').removeClass("menu-active");
		$('#menu-home').addClass('menu-active');

	}

	sendmail() {
		let self = this;
		if (self.frm.name == null) {
			return false;
		}
		if (self.frm.email == null) {
			return false;
		}
		if (self.frm.subject == null) {
			return false;
		}
		if (self.frm.message == null) {
			return false;
		}
		let data = {
			name: self.frm.name,
			email: self.frm.email,
			subject: self.frm.subject,
			message: self.frm.message
		}
		self.mail.SendMail(data);
	}
}
