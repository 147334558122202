import { Injectable } from '@angular/core';
import { globals } from './globals';

@Injectable()
export class UrlService {

	currentPlatform: any;
	tableDataCustomer: any;

	constructor(private globals: globals) {
	}
	getBaseUrl() {
		return this.globals.constants.http + this.globals.constants.host + this.globals.constants.port + this.globals.constants.path;
	}
	getBaseDataUrl() {
		return this.globals.constants.http + this.globals.constants.host + this.globals.constants.port;
	}

	generate_single_elimination_pdf(){
		return this.globals.constants.http + this.globals.constants.host + this.globals.constants.path + this.globals.constants.generate_single_elimination_pdf;
	}

	generate_round_robin_pdf(){
		return this.globals.constants.http + this.globals.constants.host + this.globals.constants.port + this.globals.constants.generate_round_robin_pdf;
	}

}