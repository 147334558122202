import { Injectable } from '@angular/core';
import { Http, Response, Headers, HttpModule, RequestOptions } from '@angular/http';

@Injectable()
export class HttpService {
	constructor(private HTTP: Http) { }
	get(url: any, header: any) {
		url = url.replace(" ", "%20");
		let self = this;
		let options = new RequestOptions({ headers: header });
		return new Promise((resolve, reject) => {
			self.HTTP.get(url, options).subscribe((response: any) => {
				let result = typeof response._body == "string" ? JSON.parse(response._body) : response._body;
				resolve(result);
			}, (error: any) => {
				reject(error);
			})
		})
	}
	post(url: any, data: any, header: any) {
		url = url.replace(" ", "%20");
		let self = this;
		let options = new RequestOptions({ headers: header });
		return new Promise((resolve, reject) => {
			self.HTTP.post(url, data, options).subscribe((response: any) => {
				// let result = typeof response._body == "string" ? JSON.parse(response._body) : response._body;
				resolve(response);
			}, (error: any) => {
				reject(error);
			})
		})
	}
	downloadPDF(url: any, data: any, header: any) {
		url = url.replace(" ", "%20");
		let self = this;
		let options = new RequestOptions({ headers: header });
		return new Promise((resolve, reject) => {
			self.HTTP.post(url, data, options).subscribe((response: any) => {
				// let result = typeof response._body == "string" ? JSON.parse(response._body) : response._body;
				resolve(response);
			}, (error: any) => {
				reject(error);
			})
		})
	}
	put(url: any, data: any, header: any) {
		url = url.replace(" ", "%20");
		let self = this;
		let headers = new Headers();
		for (var i in header) {
			headers.append(i, header[i])
		}
		return new Promise((resolve, reject) => {
			let options = new RequestOptions({ headers: headers });
			return self.HTTP.put(url, data, options).subscribe((response: any) => {
				let result = typeof response._body == "string" ? JSON.parse(response._body) : response._body;
				resolve(result);
			}, (error: any) => {
				reject(error);
			})
		});
	}
	delete(url: any, header: any) {
		url = url.replace(" ", "%20");
		let self = this;
		let options = new RequestOptions({ headers: header });
		return new Promise((resolve, reject) => {
			self.HTTP.delete(url, options).subscribe((response: any) => {
				let result = typeof response._body == "string" ? JSON.parse(response._body) : response._body;
				resolve(result);
			}, (error: any) => {
				reject(error);
			})

		})
	}

}