import { Component, OnInit } from '@angular/core';
declare var $;
declare var swal;
declare var window;
declare var canvg;
@Component({
	selector: 'app-generate-bracket',
	templateUrl: './generate-bracket.component.html',
	styleUrls: ['./generate-bracket.component.css']
})
export class GenerateBracketComponent implements OnInit {
	constructor() { }
	nameField: boolean = true;
	arr: any;
	teams: any = 0;
	rowdata: any = [];
	seeds: any = 0;
	roundrobinobj: any;
	pdfData: any;
	footerY: any;
	headerX: any = 650;
	footerYSvg: any;
	footerXSvg: any;
	/***  Single elimination */
	N: any = 0;
	UH: any = 0;
	LH: any = 0;
	BYES: any = 0;
	MATCHES: any = 0;
	HIGHESTPOWEROF2: any = 0;
	TOTALROUNDS: any = 0;
	teamPool1: any = [];
	teamPool2: any = [];
	teamPool3: any = [];
	isTeamPool2Team: boolean = false;
	match_3rd_place: boolean = false;
	lastRoundNumber: any;
	svgX: any = 0;
	svgLineX: any = 10;
	svgY: any = 0;
	matchCounter: any = 1;
	startingCounter: any = 0;
	roundStartCounterArr: any;
	roundLine1StartCounterArr: any;
	line1StartCounter: any = 1;
	line1GapCounter: any = 2;
	roundLine2StartCounterArr: any;
	line2StartCounter: any;
	line2GapCounter: any = 4;
	matchGapCounter: any = 2;
	matchStartCounter: any;
	uprHalfMatchesLength: any = 0;
	lwrHalfMatchesLength: any = 0;
	uprMatchGapCounter: any = 2;
	lwrMatchGapCounter: any = 2;
	uprHalfStartCounter: any = 1;
	lwrHalfStartCounter: any = 0;
	round1UprLinesYValue: any = [];
	round2LwrLinesYValue: any = [];
	uprLineGapCounter: boolean = false;
	lwrLineGapCounter: boolean = false;
	tournament_type:any;
	css: any = {
		box_border_color: '#00A651',
		box_border_width: '2',
		match_number_color: 'rgba(0,0,0,0.95)',
		font_size: "10",
		font_color: "#808080",
		placeholderText_color: "#808080",
		placeholderText_opacity: '0.3',
		match_blankLineColor: '#808080',
		line_color: '#00A651',
		line_border_width: '2',
	};
	matches: any = [{
		"player1": {
			"id": "1",
			"name": "sdfnksndfnsindfashfnkafwaiejfiawefiawei",
			"seed": 555,
			"score": 3,
			"image": "//ssl.gstatic.com/onebox/media/sports/logos/yJF9xqmUGenD8108FJbg9A_48x48.png"
		},
		"player2": {
			"id": "2",
			"name": "team5",
			"seed": 5,
			"score": 3,
			"image": "//ssl.gstatic.com/onebox/media/sports/logos/yJF9xqmUGenD8108FJbg9A_48x48.png"
		},
		"winner_id": null,
		"scheduled_time": "7:30 PM",
		"field": "1"
	}];
	bracketdata: any = {
		tournament_type: '',
		bracketlink: false,
		downloadlink: false,
	}
	ngOnInit() {
		let self = this;
		$('.nav-menu li').removeClass("menu-active");
		$('#menu-bracket').addClass('menu-active');
		/**	On 'click' Roundrobin (PDF button)	*/
		$(".pdfBtn").click(function () {
			$('#loader').show();
			var host = ($(location).attr('hostname'));
			var teams = self.teams;
			// var url = '//' + host + '/BracketPrint/public/api/downloadpdf';
			var url = '//' + host + '/public/api/downloadpdf';
			var filename = teams + 'teams_roundRobin_BracketPrint.pdf';
			window.downloadPDF(url, self.roundrobinobj, filename);
			self.bracketdata.downloadlink = true;
			self.bracketdata.bracketlink = false;
			self.bracket_uses(self.bracketdata);
		});
		/**	On 'click' Single elimination (PDF button)	*/
		$("#singleElimPdfBtn").click(function () {
			$('#loader').show();
			var host = ($(location).attr('hostname'));
			var teams = parseInt($('select[name=singleEliTeam]').val());
			// var url = '//' + host + '/BracketPrint/public/api/downloadpdf';
			var url = '//' + host + '/public/api/downloadpdf';
			var filename = teams + 'teams_singleElimination_BracketPrint.pdf';
			var obj = { 'svg_data': self.pdfData, 'teams': teams,'tournament_type':self.tournament_type};
			if (self.teams == 63 || self.teams == 64) {
				$('#loader').show();
				setTimeout(function () {
					var svg_header = '<svg id="svg_header" height="200" x="5" y="5"><text x="500" y="35" text-anchor="start" font-size="50" fill="#00A651">' + self.teams + ' Teams Single Elimination</text></svg>';
					var svg_footer = '<svg id="svg_footer" height="100" width="500" x="' + self.footerXSvg + '" y="' + (self.footerYSvg - 100) + '"><a xlink:href="https://bracketprit.com"><text x="0" y="50" text-anchor="start" font-size="50" font-weight = "700">Bracket</text><text x="183" y="50" text-anchor="start" font-size="50" fill="#00A651" font-weight = "700">Print</text><text x="250" y="62" text-anchor="start" font-size="20" font-weight = "700">.com</text></a></svg>';
					$('#svg').append(svg_header);
					$('#svg').append(svg_footer);
					var svg = document.getElementById('brackets').innerHTML;
					var canvas = document.createElement('canvas');
					var context = canvas.getContext('2d');
					var options = { log: false, ignoreMouse: true };
					canvg(canvas, svg, options);
					var imgData = canvas.toDataURL('image/png');
					// Generate PDF
					var doc = new window.jsPDF('p', 'pt', 'a2');
					doc.addImage(imgData, 'PNG', 60, 40, 1050, 1650);
					doc.save(self.teams+'teams_SingleElimination_bracketprint.pdf');
				}, 500);

				setTimeout(function () {
					$('#loader').hide();
					$('#svg_header').remove();
					$('#svg_footer').remove();
				}, 1500);
				return false;
			} else if (self.teams > 64) {
				var w = window.open();
				var svg_header = '<svg id="svg_header" height="200" x="5" y="5"><text x="' + (self.headerX) + '" y="35" text-anchor="start" font-size="50" fill="#00A651">' + self.teams + ' Teams Single Elimination</text></svg>';
				var svg_footer = '<svg id="svg_footer" height="100" width="500" x="' + self.footerXSvg + '" y="' + (self.footerYSvg - 100) + '"><a xlink:href="https://bracketprit.com"><text x="0" y="50" text-anchor="start" font-size="50" font-weight = "700">Bracket</text><text x="183" y="50" text-anchor="start" font-size="50" fill="#00A651" font-weight = "700">Print</text><text x="250" y="62" text-anchor="start" font-size="20" font-weight = "700">.com</text></a></svg>';
				$('#svg').append(svg_header);
				$('#svg').append(svg_footer);
				var html = $(".brackets").html();
				$(w.document.body).html(html);
				w.window.print();
				w.window.close();
				$('#svg_header').remove();
				$('#svg_footer').remove();
				$('#loader').hide();

			} else {
				window.downloadPDF(url, obj, filename);
			}
			self.bracketdata.downloadlink = true;
			self.bracketdata.bracketlink = false;
			self.bracket_uses(self.bracketdata);
		});
		/**	 On 'change' at Tournament type i.e. (Roundrobin / Single elimination)	*/
		$(document).on('change', 'select[name=tournament-type]', function (event) {
			$('.pdfBtn').hide();
			$('#singleElimPdfBtn').hide();
			var value = $(this).val();
			if (value == 'roundrobin') {
				$('.roundRobinSection').show();
				$('.singleEliminationSection').hide();
				self.tournament_type = 'roundrobin';
			} else {
				$('.singleEliminationSection').show();
				$('.roundRobinSection').hide();
				self.tournament_type = 'singleElimination';
			}
		});
		/**	On 'change' at Roundribin (Participants play each other) */
		$(document).on('change', 'select#playEachOther', function (event) {
			$('.pdfBtn').hide();
		});

		/**	On 'change' at Roundrobin (bracket size (teams)) */
		$(document).on('change', 'select#bracketSize', function (event) {
			$('.pdfBtn').hide();
			var bracketBySeedOrName = $('select[name=bracketSize]').val();
			if (bracketBySeedOrName == 2) {
				$('.blankBracket').show();
				$('.teamBracket').hide();
				self.nameField = false;
			} else {
				self.nameField = true;
				$('.blankBracket').hide();
				$('.teamBracket').show();
			}
		});
		/**	 On 'input' at Roundrobin (teamNameBox) */
		$('textarea[name=teamNameBox]').on('input', function () {
			$('.pdfBtn').hide();
			self.arr = $('#teamsName').val().split("\n");
			$.each(self.arr, function (index, val) {
				if (val !== '') {
					if (val.length > 255) {
						$(".createBrackets").attr('disabled', true);
						$('.teamNameError').show();
						return false;
					} else {
						$(".createBrackets").attr('disabled', false);
						$('.teamNameError').hide();
					}
				}
			});
		});
		/**		On 'blur' at Roundrobin (teamNameBox) 	*/
		$('textarea[name=teamNameBox]').on('blur', function () {
			self.arr = $('#teamsName').val().split("\n");
			$.each(self.arr, function (index, val) {
				if (val !== '') {
					if (val.length > 255) {
						$(".createBrackets").attr('disabled', true);
						$('.teamNameError').show();
						return false;
					} else {
						$(".createBrackets").attr('disabled', false);
						$('.teamNameError').hide();
					}
				}
			});
		});
		/** On 'input' at Roundrobin (input[name=teams]) 	*/
		$('input[name=teams]').on('input', function () {
			$('.pdfBtn').hide();
		});

		/** On 'change' at Single elimination (select[name=singleEliTeam]) 	*/
		$(document).on('change', 'select[name=singleEliTeam]', function (event) {
			$('.pdfBtn').hide();
			$('#singleElimPdfBtn').hide();
		});
		/**		On click at Single elimination (Include a match for 3rd place between semifinal losers)		*/
		$(document).on('click', 'span.third_place_match, input[name=third_place_match]', function (event) {
			var n = $("input[name=third_place_match]:checked").length;
			if (n === 1) {
				$("input[name=third_place_match]").prop("checked", false);
			} else {
				$("input[name=third_place_match]").prop("checked", true);
			}
		});

		/**  On 'click' Generate button  */
		$(".createBrackets").click(function () {
			if (!$.isNumeric($('#teams').val())) {
				self.msgAlert("Enter valid team", "red");
				return false;
			}
			$('.brackets').html('');
			self.teams = 0;
			self.arr;
			self.rowdata = [];
			self.seeds = 0;
			var tournament = $('select[name=tournament-type]').val();
			if (tournament == 'singleElimination') {
				self.teams = parseInt($('select[name=singleEliTeam]').val());
			} else {
				var bracketSize = $('select[name=bracketSize]').val();
				if (bracketSize == 1) {
					self.arr = $('#teamsName').val().split("\n");
					var arr2 = [];
					$.each(self.arr, function (index, val) {
						if (val !== '') {
							val = val.replace(/</g, '&lt;').replace(/>/g, '&gt;');
							arr2.push(self.elipsis(val, 16));
						}
					});
					var finalTeamData = [];
					self.teams = arr2.length;
					if (self.teams < 2) {
						self.msgAlert('Minimum 2 teams require to generate brackets..', 'red');
						return false;
					}
					if (self.teams > 40) {
						self.msgAlert('Maxmimum 40 teams only..', 'red');
						return false;
					}
					self.seeds = self.teams;
					for (var i = 0; i < self.teams; i++) {
						finalTeamData.push({ "teamname": (arr2[(i)]), "seeding": self.seeds });
						self.seeds--;
					}
					self.rowdata = finalTeamData;
				} else {
					self.teams = $('#teams').val();
				}
				if (self.teams < 2) {
					self.msgAlert('Minimum 2 teams require to generate brackets..', 'red');
					return false;
				}
				if (self.teams > 40) {
					self.msgAlert('Maxmimum 40 teams only..', 'red');
					return false;
				}
			}
			if (tournament === 'singleElimination') {
				self.generateSingleEliminationBracket(self.teams);
				$('#singleElimPdfBtn').show();
			} else {
				$('#singleElimPdfBtn').hide();
				var playEachOther = $('select[name=playEachOther]').val();
				if (playEachOther == 'once') {
					var matches = (self.teams * ((self.teams - 1) / 2));
					var rounds = self.teams % 2 == 0 ? (self.teams - 1) : self.teams;
					var roundArr = [];
					var value = $('select[name=bracketSize]').val();
					if (value == 2) {
						self.generateRowData(self.teams);
					}
					var counter = 0;
					for (var k = 0; k < matches; k++) {
						roundArr.push({});
					}
					for (i = self.teams - 1; i > 0; i--) {
						for (j = 0; j < i; j++) {
							roundArr[counter].player1 = { name: self.rowdata[j].teamname, SEED: self.rowdata[j].seeding };
							roundArr[counter].player2 = { name: self.rowdata[i].teamname, SEED: self.rowdata[i].seeding };
							counter++;
						}
					}
					// Shuffle(roundArr);
					var match_data = [];
					var ctr = 0;
					var per_round_matches = Math.floor(self.teams / 2);
					for (var i = 0; i < rounds; i++) {
						match_data.push([]);
						for (var j = 0; j < per_round_matches; j++) {
							match_data[i][j] = (roundArr[ctr]);
							ctr++;
						}
					}
					self.generate_roundrobin_brackets(match_data, self.teams, 'once');
					self.roundrobinobj = { 'tournament_type':self.tournament_type,'matches': matches, 'match_data': match_data, 'rounds': rounds, 'per_round_matches': per_round_matches, 'teams': parseInt(self.teams), 'nameField': self.nameField };
					$('.pdfBtn').show();
					return false;
				}

				if (playEachOther == 'twice') {
					var matches = self.teams * (self.teams - 1);
					var rounds: any = self.teams % 2 == 0 ? (self.teams * 2) - 2 : self.teams * 2;
					var roundArr = [];
					var value = $('select[name=bracketSize]').val();
					if (value == 2) {
						self.generateRowData(self.teams);
					}
					var counter = 0;
					for (var k = 0; k < matches; k++) {
						roundArr.push({});
					}

					for (i = self.teams - 1; i >= 0; i--) {
						for (var k = 0; k < self.teams; k++) {
							if (k != i) {
								roundArr[counter].player1 = { name: self.rowdata[i].teamname, SEED: self.rowdata[i].seeding };
								roundArr[counter].player2 = { name: self.rowdata[k].teamname, SEED: self.rowdata[k].seeding };
								counter++;
							}
						}
					}
					// Shuffle(roundArr);
					var match_data = [];
					var ctr = 0;
					var per_round_matches = Math.floor(self.teams / 2);
					for (var i = 0; i < rounds; i++) {
						match_data.push([]);
						for (var j = 0; j < per_round_matches; j++) {
							match_data[i][j] = (roundArr[ctr]);
							ctr++;
						}
					}
					self.generate_roundrobin_brackets(match_data, (self.teams), 'twice');
					self.roundrobinobj = { 'tournament_type':self.tournament_type,'matches': matches, 'match_data': match_data, 'rounds': rounds, 'per_round_matches': per_round_matches, 'teams': parseInt(self.teams), 'nameField': self.nameField };
					$('.pdfBtn').show();
					return false;
				}

				if (playEachOther == '3times') {
					var rounds: any = self.teams % 2 == 0 ? (self.teams * 3) - 3 : self.teams * 3;
					var roundArr = [];
					var value = $('select[name=bracketSize]').val();
					if (value == 2) {
						self.generateRowData(self.teams);
					}
					var counter = 0;
					var value = $('select[name=bracketSize]').val();
					if (value == 2) {
						self.generateRowData(self.teams);
					}
					for (var i = 0; i < self.teams; i++) {
						for (var k = i + 1; k < self.teams; k++) {
							roundArr.push({});
							roundArr[counter].player1 = self.getTeamData((i));
							roundArr[counter].player2 = self.getTeamData((k));
							counter++;
						}
					}
					for (var i = 0; i < self.teams; i++) {
						for (var k = i + 1; k < self.teams; k++) {
							roundArr.push({});
							roundArr[counter].player1 = self.getTeamData((k));
							roundArr[counter].player2 = self.getTeamData((i));
							counter++;
						}
					}
					for (var i = 0; i < self.teams; i++) {
						for (var k = i + 1; k < self.teams; k++) {
							roundArr.push({});
							roundArr[counter].player1 = self.getTeamData((i));
							roundArr[counter].player2 = self.getTeamData((k));
							counter++;
						}
					}
					// Shuffle(roundArr);
					var match_data = [];
					var ctr = 0;
					var per_round_matches = Math.floor(self.teams / 2);
					for (var i = 0; i < rounds; i++) {
						match_data.push([]);
						for (var j = 0; j < per_round_matches; j++) {
							match_data[i][j] = (roundArr[ctr]);
							ctr++;
						}
					}
					self.generate_roundrobin_brackets(match_data, self.teams, 'times3');
					self.roundrobinobj = { 'tournament_type':self.tournament_type,'matches': matches, 'match_data': match_data, 'rounds': rounds, 'per_round_matches': per_round_matches, 'teams': parseInt(self.teams), 'nameField': self.nameField };
					$('.pdfBtn').show();
					return false;
				}
			}
		});
	}
	/************************************************ */
	msgAlert(errMessage, type) {
		swal({
			title: errMessage,
			buttons: {
				ok: true,
			},
		});
	}

	elipsis(val, strlength) {
		var newVal = '';
		if (val.length > strlength) {
			var elipsis = val.slice(0, strlength).toString();
			newVal = elipsis + '...';
		} else {
			return val;
		}
		return newVal;
	}
	/****************		Roundrobin logic	*************/
	getTeamData(i) {
		let self = this;
		return { name: self.rowdata[i].teamname, SEED: self.rowdata[i].seeding };
	}
	generateRowData(matches) {
		let self = this;
		var data = [];
		for (var i = 0; i < matches; i++) {
			data.push({ "teamname": "", "seeding": (i + 1) });
		}
		self.rowdata = data;
	}
	roundrobin_get_html_match(match) {
		var html = '';
		var player1seed = parseInt(match.player1.SEED) ? parseInt(match.player1.SEED) : '';
		var player2seed = parseInt(match.player2.SEED) ? parseInt(match.player2.SEED) : '';

		var player1name = match.player1.name ? match.player1.name : '';
		var player2name = match.player2.name ? match.player2.name : '';

		var player1score = parseInt(match.player1.SCORE) ? parseInt(match.player1.SCORE) : '';
		var player2score = parseInt(match.player2.SCORE) ? parseInt(match.player2.SCORE) : '';

		html = '<div class="roundrobin_match">\
					<div class="match1">\
						<div class="seed">'+ player1seed + '</div>\
						<div class="team">'+ player1name + '</div>\
						<div class="score">'+ player1score + '</div>\
					</div>\
					<div class="match2">\
							<div class="seed">'+ player2seed + '</div>\
							<div class="team">'+ player2name + '</div>\
							<div class="score">'+ player2score + '</div>\
					</div>\
				</div>';
		return html;
	}
	roundrobin_get_html(rounds) {
		let self = this;
		var html = '';
		html += '<div class="container-brackets">';
		$.each(rounds, function (r, round) {
			html += '<div class="round rd-' + (r + 1) + '">';
			html += '<p class="roundNumber"> Round ' + (r + 1) + ' </p>';
			$.each(round, function (r, match) {
				html += self.roundrobin_get_html_match(match);
			});
			html += '</div>';
		});
		html += '</div>';
		return html;
	}
	generate_roundrobin_brackets(rounds, teams, type) {
		let self = this;
		var container_brackets = this.roundrobin_get_html(rounds);
		$(".brackets").html(container_brackets);

		self.bracketdata.downloadlink = false;
		self.bracketdata.bracketlink = true;
		self.bracket_uses(self.bracketdata);

		var brackets_width = 250;
		if (teams > 3) {
			brackets_width = brackets_width * 4;
			$(".brackets .container-brackets > div:last-child").css('padding-bottom', '30px');
		} else {
			if (teams == 3 && type == 'once') {
				brackets_width = brackets_width * 3;
				$(".brackets .container-brackets > div:last-child").css('padding-bottom', '30px');
			}
			if (type == 'twice') {
				brackets_width = brackets_width * 2;
			}
			if (type == 'times3') {
				brackets_width = brackets_width * 3;
			}
			$(".brackets .container-brackets").css('padding-bottom', '130px');
		}
		$("#brackets-preview").css('width', brackets_width + 'px');
	}
	/**********		Single elimination logic		**********/
	generateSingleEliminationBracket(team) {
		let self = this;
		self.resetValues();
		self.generateTeamPools();
		$('.brackets').html('');

		if (self.teams == 2) {
			self.generate2TeamBrackets();
			return false;
		}
		self.N = self.teams;
		self.UH = (self.N % 2 == 0) ? self.N / 2 : (self.N - 1) / 2;
		self.LH = (self.N % 2 == 0) ? self.N / 2 : (self.N + 1) / 2;
		var power2 = [2];
		for (i = 2; i <= self.N; i++) {
			if (Math.pow(2, i) == self.teams || Math.pow(2, i) > self.teams) {
				power2.push(Math.pow(2, i));
				self.HIGHESTPOWEROF2 = Math.pow(2, i);
				self.TOTALROUNDS = i;
				break;
			}
			power2.push(Math.pow(2, i));
		}
		self.BYES = (self.HIGHESTPOWEROF2 - self.N);
		self.MATCHES = self.N - 1;
		var teamArray = [];
		for (var i = 0; i < self.N; i++) {
			teamArray.push('team' + (i + 1));
		}
		var teamCounter = 1;
		var upperHalfArray = [];
		for (var i = 0; i < self.UH; i++) {
			var obj = {
				teamdata: { 'teamname': ('team' + teamCounter), 'seed': '' },
				isBye: false
			};
			upperHalfArray.push(obj);
			teamCounter++;
		}
		var lowerHalfArray = [];
		for (var i = 0; i < self.LH; i++) {
			var obj = {
				teamdata: { 'teamname': ('team' + teamCounter), 'seed': '' },
				isBye: false
			};
			lowerHalfArray.push(obj);
			teamCounter++;
		}
		var UHPOSITION = true, LHPOSITION = false;
		var UHC = 0, UHRC = upperHalfArray.length;
		var UHLoopLimit = (self.N % 2 == 0) ? (self.BYES / 2) : Math.ceil(self.BYES / 2);
		for (var i = 0; i < UHLoopLimit; i++) {
			if (UHPOSITION) {
				upperHalfArray[(UHRC - 1)].isBye = true;
				UHRC--;
				UHPOSITION = false;
			} else {
				upperHalfArray[UHC].isBye = true;
				UHC++;
				UHPOSITION = true;
			}
		}
		var LHC = 0, LHRC = lowerHalfArray.length;
		var LHLoopLimit = (self.N % 2 == 0) ? (self.BYES / 2) : Math.floor(self.BYES / 2);
		for (var i = 0; i < LHLoopLimit; i++) {
			if (LHPOSITION) {
				lowerHalfArray[(LHRC - 1)].isBye = true;
				LHRC--;
				LHPOSITION = false;
			} else {
				lowerHalfArray[LHC].isBye = true;
				LHC++;
				LHPOSITION = true;
			}
		}
		var UHTeams = [], LHTeams = [];
		for (var i = 0; i < upperHalfArray.length; i++) {
			var arritem = upperHalfArray[i];
			if (!arritem.isBye) {
				UHTeams.push(arritem);
			}
		}
		for (var i = 0; i < lowerHalfArray.length; i++) {
			var arritem = lowerHalfArray[i];
			if (!arritem.isBye) {
				LHTeams.push(arritem);
			}
		}
		var finalRounds = [];
		for (var i = 0; i < self.TOTALROUNDS; i++) {
			finalRounds.push([]);
		}
		let teamsinRound = 0, matchesInRound = 0, counter = 0, dataCounter = 0;
		for (var r = 0; r < self.TOTALROUNDS; r++) {
			if ((r + 1) == 1) {
				teamsinRound = parseInt(self.teams) - parseInt(self.BYES);
				matchesInRound = Math.floor(teamsinRound / 2);
				teamsinRound = matchesInRound;
				for (var i = 0; i < matchesInRound; i++) {
					finalRounds[counter][i] = self.single_eli_create_playerobj(self.matches[dataCounter]);
				}
				counter++;
			} else {
				if (self.BYES == 0) {
					matchesInRound = Math.floor(teamsinRound / 2);
					teamsinRound = matchesInRound;
					for (var i = 0; i < matchesInRound; i++) {
						finalRounds[counter][i] = self.single_eli_create_playerobj(self.matches[dataCounter]);
					}
					counter++;
				} else {
					if ((r + 1) == 2) {
						teamsinRound = (teamsinRound) + parseInt(self.BYES);
						matchesInRound = Math.floor(teamsinRound / 2);
						teamsinRound = matchesInRound;
						for (var i = 0; i < matchesInRound; i++) {
							finalRounds[counter][i] = self.single_eli_create_playerobj(self.matches[dataCounter]);
						}
						counter++;
					} else {
						matchesInRound = Math.floor(teamsinRound / 2);
						teamsinRound = matchesInRound;
						for (var i = 0; i < matchesInRound; i++) {
							finalRounds[counter][i] = self.single_eli_create_playerobj(self.matches[dataCounter]);
						}
						counter++;
					}
				}
			}
		}
		var options = false;
		if (self.teams > 3) {
			options = self.matchFor3rdPlace();
		}
		if (self.teamPool1.indexOf(self.N) !== -1) {
			self.singleEliminationTeamsPool1Pool2(finalRounds, options);
		}
		if (self.teamPool2.indexOf(self.N) !== -1) {
			self.isTeamPool2Team = true;
			self.singleEliminationTeamsPool1Pool2(finalRounds, options);
		}
		if (self.teamPool3.indexOf(self.N) !== -1) {
			self.singleEliminationTeamsPool3(finalRounds, options);
		}
	}
	matchFor3rdPlace() {
		var n = $("input[name=third_place_match]:checked").length;
		return (n === 1 ? true : false);
	}
	/*****	Pool1 and Pool2	*****/
	singleEliminationTeamsPool1Pool2(finalRounds, options) {
		let self = this;
		self.svgX = 0;
		self.svgLineX = 10;
		self.svgY = 0;
		self.matchCounter = 1;
		self.startingCounter = 0;
		self.roundStartCounterArr = [0, 1, 3];
		self.roundLine1StartCounterArr = [1, 2];
		self.line1StartCounter = 1;
		self.line1GapCounter = 2;
		self.roundLine2StartCounterArr = [2];
		self.line2StartCounter;
		self.line2GapCounter = 4;
		if (options) {
			self.match_3rd_place = true;
			self.lastRoundNumber = (finalRounds.length - 1);
		} else {
			self.match_3rd_place = false;
		}
		if (self.isTeamPool2Team) {
			self.roundStartCounterArr = [1, 0, 2];
			self.roundLine1StartCounterArr = [0, 1];
			self.roundLine2StartCounterArr = [1];
		}
		var roundcounter = 4;
		var lastVal = (self.isTeamPool2Team) ? 2 : 3;
		for (var i = 0; i < self.TOTALROUNDS; i++) {
			var tmp = lastVal + roundcounter;
			self.roundStartCounterArr.push(tmp);
			lastVal = tmp;
			roundcounter = roundcounter * 2;
		}
		var lastLineVal = 2;
		for (var i = 0; i < self.TOTALROUNDS; i++) {
			var tmp = lastLineVal *= 2;
			if (self.isTeamPool2Team) {
				self.roundLine1StartCounterArr.push(tmp - 1);
			} else {
				self.roundLine1StartCounterArr.push(tmp);
			}
			lastLineVal = tmp;
		}
		for (var i = 0; i < self.TOTALROUNDS; i++) {
			var tmp = self.roundLine2StartCounterArr[i] * 2;
			if (self.isTeamPool2Team) {
				self.roundLine2StartCounterArr.push(tmp + 1);
			} else {
				self.roundLine2StartCounterArr.push(tmp);
			}
		}
		if (self.isTeamPool2Team) {
			var svgHeight = ((finalRounds[0].length * 100) + (finalRounds[0].length * 20) + 60);
		} else {
			var svgHeight = ((finalRounds[0].length * 100) + (finalRounds[0].length * 20));
		}
		var svgWidth = (self.TOTALROUNDS * 240);
		svgHeight = (options && self.teams == 4) ? (svgHeight + 100) : svgHeight;
		self.footerYSvg = svgHeight;
		var brackets = self.pool1_bracket(finalRounds, svgWidth, svgHeight);
		$('.brackets-preview').css('width', '' + svgWidth + 'px');
		$('.brackets').html(brackets);
		let obj = {
			brackets: brackets,
			bracketsHeight: svgHeight,
			bracketsWidth: svgWidth
		}
		self.pdfData = obj;
		self.bracketdata.bracketlink = true;
		self.bracketdata.downloadlink = false;
		self.bracket_uses(self.bracketdata);
	}
	pool1_bracket(rounds, svgWidth, svgHeight) {
		let self = this;
		var halfRound = (self.teams >= 255) ? (Math.floor(rounds.length / 2) - 1) : (Math.floor(rounds.length / 2));

		var html = '';
		html += '<svg id="svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="' + svgWidth + '" height="' + (svgHeight + 10) + '">';
		html += '<g>';
		$.each(rounds, function (r, round) {
			self.matchGapCounter = (r == 0) ? 2 : (self.matchGapCounter * 2);
			self.matchStartCounter = self.roundStartCounterArr[r];
			html += self.pool1_get_match(r, round);
			self.svgX = 240 * (r + 1);
			if ((r + 1) == halfRound) {
				self.footerXSvg = self.svgX;
			}
		});
		html += '</g>';
		html += '<g>';
		$.each(rounds, function (r, round) {
			if ((rounds.length != (r + 1))) {
				self.line1StartCounter = self.roundLine1StartCounterArr[r];
				self.line1GapCounter = (self.line1GapCounter * 2);
				self.line2StartCounter = self.roundLine2StartCounterArr[r];
				html += self.pool1_svg_lines(r, round);
				self.line2GapCounter = (self.line2GapCounter * 2);
				self.svgLineX = (((230 * (r + 1)) + (10 * (r + 1))) + 10);
			}
		});
		html += '</g>';
		html += '</svg>';
		return html;
	}
	pool1_svg_lines(roundNum, round) {
		let self = this;
		var html = '';
		var line1Y = 1;
		var line1YCounter = self.line1StartCounter;
		var line2Y = 1;
		var line2YCounter = self.line2StartCounter;
		var linePath = (self.isTeamPool2Team) ? ((self.line1StartCounter + 1) * 60) : (self.line1StartCounter * 60);
		for (var i = 0; i < (round.length / 2); i++) {
			if (self.isTeamPool2Team) {
				if (roundNum == 0 && i == 0) {
					line1Y = (parseInt(line1YCounter) * 60);
					line1YCounter = self.line1GapCounter + line1YCounter;
				} else {
					line1Y = (parseInt(line1YCounter) * 60);
					line1YCounter = self.line1GapCounter + line1YCounter;
					html += '<svg x="' + self.svgLineX + '" y="' + (line1Y - 10) + '">' +
						'<path stroke="#00A651" stroke-width="2" fill="none" d="M 200 1 L 210 1 L 210 ' + linePath + '"></path>' +
						'</svg>';
				}
				line2Y = (parseInt(line2YCounter) * 60);
				line2YCounter = self.line2GapCounter + line2YCounter;
				html += '<svg x="' + self.svgLineX + '" y="' + (line2Y - 10) + '">' +
					'<path stroke="#00A651" stroke-width="2" fill="none" d="M 200 ' + linePath + ' L 210 ' + linePath + ' L 210 1 L 220 1"></path>' +
					'</svg>';
			} else {
				line1Y = (parseInt(line1YCounter) * 60);
				line1YCounter = self.line1GapCounter + line1YCounter;
				html += '<svg x="' + self.svgLineX + '" y="' + (line1Y - 10) + '">' +
					'<path stroke="#00A651" stroke-width="2" fill="none" d="M 200 1 L 210 1 L 210 ' + linePath + '"></path>' +
					'</svg>';

				line2Y = (parseInt(line2YCounter) * 60);
				line2YCounter = self.line2GapCounter + line2YCounter;
				html += '<svg x="' + self.svgLineX + '" y="' + (line2Y - 10) + '">' +
					'<path stroke="#00A651" stroke-width="2" fill="none" d="M 200 ' + linePath + ' L 210 ' + linePath + ' L 210 1 L 220 1"></path>' +
					'</svg>';
			}
		}
		return html;
	}

	pool1_get_match(roundNum, match) {
		let self = this;
		var html = '';
		var matchY = 0;
		if (self.isTeamPool2Team) {
			var matchYCounter = self.matchStartCounter;
		} else {
			var matchYCounter = (roundNum == 0) ? 0 : self.matchStartCounter;
		}
		$.each(match, function (r, player) {
			matchY = (parseInt(matchYCounter) * 60);
			matchYCounter = self.matchGapCounter + matchYCounter;
			html += self.single_eli_bracket_html(self.svgX, matchY, self.matchCounter, player, false);
			self.matchCounter++;
			if (self.match_3rd_place) {
				if (roundNum == self.lastRoundNumber) {
					html += self.single_eli_bracket_html((self.svgX + 10), (matchY + 150), self.matchCounter, {}, true);
				}
			}
		});
		return html;
	}
	/*****	Pool3	*****/
	singleEliminationTeamsPool3(finalRounds, options) {
		let self = this;
		self.svgX = 0;
		self.svgLineX = 10;
		self.svgY = 0;
		self.matchCounter = 1;
		self.startingCounter = 0;
		self.roundStartCounterArr = [1, 0, 2];
		self.roundLine1StartCounterArr = [0, 1];
		self.line1StartCounter = 1;
		self.line1GapCounter = 2;
		self.roundLine2StartCounterArr = [1];
		self.line2StartCounter;
		self.line2GapCounter = 4;
		if (options) {
			self.match_3rd_place = true;
			self.lastRoundNumber = (finalRounds.length - 1);
		} else {
			self.match_3rd_place = false;
		}
		var roundcounter = 4;
		var lastVal = 2
		for (var i = 0; i < self.TOTALROUNDS; i++) {
			var tmp = (lastVal + roundcounter);
			self.roundStartCounterArr.push(tmp);
			lastVal = tmp;
			roundcounter = (roundcounter * 2);
		}

		var lastLineVal = 2;
		for (var i = 0; i < self.TOTALROUNDS; i++) {
			var tmp = (lastLineVal *= 2);
			self.roundLine1StartCounterArr.push(tmp - 1);
			lastLineVal = tmp;
		}

		for (var i = 0; i < self.TOTALROUNDS; i++) {
			var tmp = self.roundLine2StartCounterArr[i] * 2;
			self.roundLine2StartCounterArr.push(tmp + 1);
		}

		var round1TotalMatches = finalRounds[0].length;
		self.uprHalfMatchesLength = (self.teams == 11 || self.teams == 19) ? Math.floor((round1TotalMatches / 2)) : Math.ceil((round1TotalMatches / 2));
		self.lwrHalfMatchesLength = (self.teams == 11 || self.teams == 19) ? Math.ceil((round1TotalMatches / 2)) : Math.floor((round1TotalMatches / 2));

		self.uprMatchGapCounter = ((self.uprHalfMatchesLength % 2) == 1) ? 2 : ((self.teams == 20) ? 8 : 4);
		self.lwrMatchGapCounter = ((self.lwrHalfMatchesLength % 2) == 1) ? 2 : ((self.teams == 20) ? 8 : 4);

		self.uprHalfStartCounter = 1;
		self.lwrHalfStartCounter = ((finalRounds[1].length * 2) + 1);

		self.round1UprLinesYValue = [];
		self.round2LwrLinesYValue = [];

		self.uprLineGapCounter = ((self.uprHalfMatchesLength % 2) == 1) ? true : false;
		self.lwrLineGapCounter = ((self.lwrHalfMatchesLength % 2) == 1) ? true : false;

		let uprHalfLineStart = self.uprHalfStartCounter;
		for (var i = 0; i < self.uprHalfMatchesLength; i++) {
			if (i == 0) {
				self.round1UprLinesYValue.push(uprHalfLineStart);
				var increseVal = (self.uprLineGapCounter) ? 3 : 4;
				uprHalfLineStart = uprHalfLineStart + increseVal + ((self.teams == 20) ? increseVal : 0);
			} else {
				self.round1UprLinesYValue.push(uprHalfLineStart);
				uprHalfLineStart++;
			}
		}

		let lwrHalfLineStart = self.lwrHalfStartCounter;
		for (var i = 0; i < self.lwrHalfMatchesLength; i++) {
			if (i == 0) {
				self.round2LwrLinesYValue.push(lwrHalfLineStart);
				var increseVal = (self.lwrLineGapCounter) ? 3 : 4;

				lwrHalfLineStart = lwrHalfLineStart + increseVal + ((self.teams == 20) ? increseVal : 0);
			} else {
				self.round2LwrLinesYValue.push(lwrHalfLineStart);
				lwrHalfLineStart++;
			}
		}
		if (finalRounds[0].length == finalRounds[1].length) {
			var svgHeight = (((finalRounds[1].length + (finalRounds[1].length - 1)) * 110) + ((finalRounds[2].length + 1) * 20) + 80);
		} else {
			var svgHeight = (((finalRounds[1].length + (finalRounds[1].length - 1)) * 110) + ((finalRounds[2].length + 1) * 20) + 20);
			svgHeight = svgHeight + ((self.teams > 10) ? 70 : 0);
		}
		var svgWidth = (self.TOTALROUNDS * 240);
		svgHeight = (options && self.teams == 5) ? (svgHeight + 30) : svgHeight;
		self.footerYSvg = svgHeight;
		var brackets = self.pool3_bracket(finalRounds, svgWidth, svgHeight);
		$('.brackets-preview').css('width', '' + svgWidth + 'px');
		$('.brackets').html(brackets);
		let obj = {
			brackets: brackets,
			bracketsHeight: svgHeight,
			bracketsWidth: svgWidth
		}
		self.pdfData = obj;
		self.bracketdata.bracketlink = true;
		self.bracketdata.downloadlink = false;
		self.bracket_uses(self.bracketdata);
	}

	pool3_bracket(rounds, svgWidth, svgHeight) {
		let self = this;
		var html = '';
		html += html += '<svg id="svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="' + svgWidth + '" height="' + svgHeight + '">';
		html += '<g>';
		$.each(rounds, function (r, round) {
			self.matchGapCounter = (r == 0) ? 2 : (self.matchGapCounter * 2);
			self.matchStartCounter = self.roundStartCounterArr[r];
			html += self.pool3_get_match(r, round);
			self.svgX = (240 * (r + 1));
		});
		html += '</g>';
		html += '<g>';
		$.each(rounds, function (r, round) {
			if ((rounds.length != (r + 1))) {
				self.line1StartCounter = self.roundLine1StartCounterArr[r];
				self.line1GapCounter = (self.line1GapCounter * 2);
				self.line2StartCounter = self.roundLine2StartCounterArr[r];
				html += self.pool3_svg_lines(r, round);
				self.line2GapCounter = (self.line2GapCounter * 2);
				self.svgLineX = (((230 * (r + 1)) + (10 * (r + 1))) + 10);
			}
		});
		html += '</g>';
		html += '</svg>';
		return html;
	}
	pool3_svg_lines(roundNum, round) {
		let self = this;
		var html = '';
		var line1Y = 1;
		var line1YCounter = self.line1StartCounter;
		var line2Y = 1;
		var line2YCounter = self.line2StartCounter;
		var linePath = ((self.line1StartCounter + 1) * 60);

		if (roundNum == 0) {

			for (var i = 0; i < self.uprHalfMatchesLength; i++) {
				if (i == 0) {
					line2Y = (parseInt(self.round1UprLinesYValue[i]) * 60);
					html += '<svg x="' + self.svgLineX + '" y="' + (line2Y - 10) + '">' +
						'<path stroke="#00A651" stroke-width="2" fill="none" d="M 200 ' + linePath + ' L 210 ' + linePath + ' L 210 1 L 220 1"></path>' +
						'</svg>';
				} else {
					if (!self.uprLineGapCounter) {
						line2Y = (parseInt(self.round1UprLinesYValue[i]) * 60);
						html += '<svg x="' + self.svgLineX + '" y="' + (line2Y - 10) + '">' +
							'<path stroke="#00A651" stroke-width="2" fill="none" d="M 200 ' + linePath + ' L 210 ' + linePath + ' L 210 1 L 220 1"></path>' +
							'</svg>';
					} else {
						if (i % 2 == 1) {
							line1Y = (parseInt(self.round1UprLinesYValue[i]) * 60);
							html += '<svg x="' + self.svgLineX + '" y="' + (line1Y - 10) + '">' +
								'<path stroke="#00A651" stroke-width="2" fill="none" d="M 200 1 L 210 1 L 210 ' + linePath + '"></path>' +
								'</svg>';
						} else {
							line2Y = (parseInt(self.round1UprLinesYValue[i]) * 60);
							html += '<svg x="' + self.svgLineX + '" y="' + (line2Y - 10) + '">' +
								'<path stroke="#00A651" stroke-width="2" fill="none" d="M 200 ' + linePath + ' L 210 ' + linePath + ' L 210 1 L 220 1"></path>' +
								'</svg>';
						}
					}
				}
			}
			for (var i = 0; i < self.lwrHalfMatchesLength; i++) {
				if (i == 0) {
					line2Y = (parseInt(self.round2LwrLinesYValue[i]) * 60);
					html += '<svg x="' + self.svgLineX + '" y="' + (line2Y - 10) + '">' +
						'<path stroke="#00A651" stroke-width="2" fill="none" d="M 200 ' + linePath + ' L 210 ' + linePath + ' L 210 1 L 220 1"></path>' +
						'</svg>';
				} else {
					if (!self.lwrLineGapCounter) {
						line2Y = (parseInt(self.round2LwrLinesYValue[i]) * 60);
						html += '<svg x="' + self.svgLineX + '" y="' + (line2Y - 10) + '">' +
							'<path stroke="#00A651" stroke-width="2" fill="none" d="M 200 ' + linePath + ' L 210 ' + linePath + ' L 210 1 L 220 1"></path>' +
							'</svg>';
					} else {
						if (i % 2 == 1) {
							line1Y = (parseInt(self.round2LwrLinesYValue[i]) * 60);
							html += '<svg x="' + self.svgLineX + '" y="' + (line1Y - 10) + '">' +
								'<path stroke="#00A651" stroke-width="2" fill="none" d="M 200 1 L 210 1 L 210 ' + linePath + '"></path>' +
								'</svg>';
						} else {
							line2Y = (parseInt(self.round2LwrLinesYValue[i]) * 60);
							html += '<svg x="' + self.svgLineX + '" y="' + (line2Y - 10) + '">' +
								'<path stroke="#00A651" stroke-width="2" fill="none" d="M 200 ' + linePath + ' L 210 ' + linePath + ' L 210 1 L 220 1"></path>' +
								'</svg>';
						}
					}
				}
			}
		} else {
			for (var i = 0; i < (round.length / 2); i++) {
				line1Y = (parseInt(line1YCounter) * 60);
				line1YCounter = self.line1GapCounter + line1YCounter;
				html += '<svg x="' + self.svgLineX + '" y="' + (line1Y - 10) + '">' +
					'<path stroke="#00A651" stroke-width="2" fill="none" d="M 200 1 L 210 1 L 210 ' + linePath + '"></path>' +
					'</svg>';

				line2Y = (parseInt(line2YCounter) * 60);
				line2YCounter = self.line2GapCounter + line2YCounter;
				html += '<svg x="' + self.svgLineX + '" y="' + (line2Y - 10) + '">' +
					'<path stroke="#00A651" stroke-width="2" fill="none" d="M 200 ' + linePath + ' L 210 ' + linePath + ' L 210 1 L 220 1"></path>' +
					'</svg>';
			}
		}
		return html;
	}
	pool3_get_match(roundNum, match) {
		let self = this;
		var html = '';
		var matchY = 0;
		var matchYCounter = self.matchStartCounter;
		if (roundNum == 0) {
			matchYCounter = self.uprHalfStartCounter;
			for (var i = 0; i < self.uprHalfMatchesLength; i++) {
				matchY = (parseInt(matchYCounter) * 60);
				matchYCounter = self.uprMatchGapCounter + matchYCounter;
				html += self.single_eli_bracket_html(self.svgX, matchY, self.matchCounter, {}, false);
				self.matchCounter++;
			}
			matchYCounter = self.lwrHalfStartCounter;
			for (var i = 0; i < self.lwrHalfMatchesLength; i++) {
				matchY = (parseInt(matchYCounter) * 60);
				matchYCounter = self.lwrMatchGapCounter + matchYCounter;
				html += self.single_eli_bracket_html(self.svgX, matchY, self.matchCounter, {}, false);
				self.matchCounter++;
			}
		} else {
			$.each(match, function (r, player) {
				matchY = (parseInt(matchYCounter) * 60);
				matchYCounter = self.matchGapCounter + matchYCounter;
				html += self.single_eli_bracket_html(self.svgX, matchY, self.matchCounter, player, false);
				self.matchCounter++;
				if (self.match_3rd_place) {
					if (roundNum == self.lastRoundNumber) {
						html += self.single_eli_bracket_html(self.svgX, (matchY + 150), self.matchCounter, {}, true);
					}
				}
			});
		}
		return html;
	}
	generate2TeamBrackets() {
		let self = this;
		var html = '<svg xmlns="http://www.w3.org/2000/svg" width="230" height="130">';
		html += self.single_eli_bracket_html(0, 0, 1, self.single_eli_create_playerobj(self.matches[0]), false);
		html += '</svg>';
		$('.brackets').html(html);
		let obj = {
			brackets: html,
			bracketsHeight: 130,
			bracketsWidth: 230
		}
		self.pdfData = obj;
		$('.brackets-preview').css('width', '230px');
		self.bracketdata.bracketlink = true;
		self.bracketdata.downloadlink = false;
		self.bracket_uses(self.bracketdata);
		return false;
	}
	resetValues() {
		let self = this;
		self.N = 0;
		self.UH = 0;
		self.LH = 0;
		self.BYES = 0;
		self.MATCHES = 0;
		self.HIGHESTPOWEROF2 = 0;
		self.TOTALROUNDS = 0;
		self.teamPool1 = [];
		self.teamPool2 = [];
		self.teamPool3 = [];
		self.isTeamPool2Team = false;
	}
	generateTeamPools() {
		let self = this;
		for (var j = 3; j <= 256; j++) {
			var BYES = 0;
			var MATCHES;
			var HIGHESTPOWEROF2 = 0;
			var teams = j;
			self.N = teams;
			var power2 = [2];
			for (var i = 2; i <= self.N; i++) {
				if (Math.pow(2, i) == teams || Math.pow(2, i) > teams) {
					power2.push(Math.pow(2, i));
					HIGHESTPOWEROF2 = Math.pow(2, i);
					break;
				}
				power2.push(Math.pow(2, i));
			}
			BYES = HIGHESTPOWEROF2 - self.N;
			MATCHES = self.N - 1;
			if (BYES == 0) {
				self.teamPool1.push(j);
			}
			if (BYES == 1) {
				self.teamPool2.push(j);
			}
			if (BYES !== 0 && BYES !== 1) {
				self.teamPool3.push(j);
			}
		}
	}
	single_eli_create_playerobj(data) {
		let self = this;
		return {
			player1: {
				"id": data.player1.id,
				"name": (data.player1.id) ? self.elipsis(data.player1.name, 15) : data.player1.name,
				"seed": data.player1.seed,
				"score": data.player1.score,
				"image": data.player1.image
			},
			player2: {
				"id": data.player2.id,
				"name": (data.player2.id) ? self.elipsis(data.player2.name, 15) : data.player2.name,
				"seed": data.player2.seed,
				"score": data.player2.score,
				"image": data.player2.image
			},
			"id": data.player1.id,
			"scheduled_time": data.scheduled_time,
			"field": data.field
		};
	}
	single_eli_bracket_html(sx, sy, match_counter, player, match_3rd_place) {
		let self = this;
		var html = '';
		if (match_3rd_place) {
			html += '<text class="third-place-match-label"  x="' + sx + '" y="' + (sy - 7) + '">3rd Place</text>';
			sx = (sx + 10);
			sy = (sy + 10);
			match_counter = '';
		}
		html += '<svg x="' + sx + '" y="' + sy + '" class="">\
					<rect x="26" y="1" width="170" height="99" rx="5" ry="5" class="" fill="rgb(255,255,255)" stroke="' + self.css.box_border_color + '" stroke-width="' + self.css.box_border_width + '"></rect>\
					<rect x="26" y="1" width="170" height="99" rx="5" ry="5" class="" fill="rgb(255,255,255)" stroke="' + self.css.box_border_color + '" stroke-width="' + self.css.box_border_width + '"></rect>\
					<text x="7" y="55"  text-anchor="middle" font-size="' + self.css.font_size + '" fill="' + self.css.font_color + '" class="atch--identifier">' + match_counter + '</text>\
					<svg x="35" y="16" class="">\
						<text x="0" y="10" text-anchor="start" font-size="'+ self.css.font_size + '" fill="' + self.css.font_color + '" class="atch--fieldText">Field: </text>\
						<path d="M26 10 h16 v1 h-16 v-1" fill="'+ self.css.match_blankLineColor + '" stroke-width="0"></path>\
					</svg>\
					<svg x="85" y="16" class="">\
						<text x="0" y="10" text-anchor="start" font-size="'+ self.css.font_size + '" fill="' + self.css.font_color + '" class="atch--dateTimeText">Time:</text>\
						<path d="M26 10 h75 v1 h-75 v-1" fill="'+ self.css.match_blankLineColor + '" stroke-width="0"></path>\
					</svg>\
					<g clip-path="">\
						<svg x="0" y="0" class="">\
							<g>\
								<svg x="2" y="35" class="">\
								<path d="M 35 22 h25 v1 h-25" fill="'+ self.css.match_blankLineColor + '" stroke-width="0"></path>\
								<text x="36" y="21" text-anchor="start" font-size="'+ self.css.font_size + '" fill="' + self.css.placeholderText_color + '" class="atch--placeholderText" style="opacity: ' + self.css.placeholderText_opacity + ';">Seed</text>\
								</svg>\
							</g>\
							<g>\
								<svg x="65" y="35" class="">\
								<path d="M 5 22 h85 v1 h-85" fill="'+ self.css.match_blankLineColor + '" stroke-width="0"></path>\
								<text x="5" y="21" text-anchor="start" font-size="'+ self.css.font_size + '" fill="' + self.css.placeholderText_color + '" style="opacity: ' + self.css.placeholderText_opacity + ';" class="atch--placeholderText">Teamname</text>\
								</svg>\
							</g>\
							<g>\
								<svg x="155" y="35" class="">\
								<path d="M 5 22 h25 v1 h-25" fill="'+ self.css.match_blankLineColor + '" stroke-width="0"></path>\
								<text x="5" y="21" text-anchor="start" font-size="'+ self.css.font_size + '" fill="' + self.css.placeholderText_color + '" style="opacity: ' + self.css.placeholderText_opacity + ';" class="atch--placeholderText">Score</text>\
								</svg>\
							</g>\
						</svg>\
						<svg x="0" y="32" class="">\
							<g>\
								<svg x="2" y="35" class="">\
								<path d="M 35 22 h25 v1 h-25" fill="'+ self.css.match_blankLineColor + '" stroke-width="0"></path>\
								<text x="36" y="21" text-anchor="start" font-size="'+ self.css.font_size + '" fill="' + self.css.placeholderText_color + '" style="opacity: ' + self.css.placeholderText_opacity + ';" class="atch--placeholderText">Seed</text>\
								</svg>\
							</g>\
							<g>\
								<svg x="65" y="35" class="">\
								<path d="M 5 22 h85 v1 h-85" fill="'+ self.css.match_blankLineColor + '" stroke-width="0"></path>\
								<text x="5" y="21" text-anchor="start" font-size="'+ self.css.font_size + '" fill="' + self.css.placeholderText_color + '" style="opacity: ' + self.css.placeholderText_opacity + ';" class="atch--placeholderText">Teamname</text>\
								</svg>\
							</g>\
							<g>\
								<svg x="155" y="35" class="">\
								<path d="M 5 22 h25 v1 h-25" fill="'+ self.css.match_blankLineColor + '" stroke-width="0"></path>\
								<text x="5" y="21" text-anchor="start" font-size="'+ self.css.font_size + '" fill="' + self.css.placeholderText_color + '" style="opacity: ' + self.css.placeholderText_opacity + ';" class="atch--placeholderText">Score</text>\
								</svg>\
							</g>\
						</svg>\
            		</g>\
				</svg>';
		return html;
	}
	bracket_uses(bracketdata) {
		let self = this;
		var host = ($(location).attr('hostname'));
		var tour_type = ['roundrobin', 'singleElimination'];
		var tournament = $('select[name=tournament-type]').val();
		if (tour_type.indexOf(tournament) != -1) {
		} else {
			self.msgAlert('Something went wrong.. Try again later!', 'red');
			return false;
		}
		bracketdata.tournament_type = tournament;
		let senddata = {
			"tournament_type": tournament,
			"bracketlink": bracketdata.bracketlink,
			"downloadlink": bracketdata.downloadlink
		};
		$.ajax({
			type: 'post',
			url: '//' + host + '/public/api/bracketUses',
			// url: '//' + host + '/BracketPrint/public/api/bracketUses',
			data: senddata,
			dataType: "json",
		}).done(function (response) {
		}).fail(function (response) {
		});
	}
}
