import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '../app/home/home.component';
// import { AuthGuard } from '../shared/guard';
import { AboutusComponent } from '../app/aboutus/aboutus.component';
import { GenerateBracketComponent } from '../app/generate-bracket/generate-bracket.component';

const router: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: 'home', component: HomeComponent, pathMatch: 'full' },
	{ path: 'aboutus', component: AboutusComponent, pathMatch: 'full' },
	{ path: 'generate-bracket', component: GenerateBracketComponent, pathMatch: 'full' },
]
@NgModule({
	imports: [
		CommonModule,
		RouterModule.forRoot(router)
	],
	exports: [
		RouterModule
	],
	declarations: []
})
export class AppRoutingModule { }
