import { Injectable }from '@angular/core'; 
import { UrlService,HttpService }from '../shared'; 
declare var toastr;
@Injectable()
export class Bracketprintapi {
    constructor(private HttpService:HttpService, private UrlService:UrlService) {}
    
    generate_single_elimination_pdf(data:any){
        let self = this;
        //console.log("Data:",data);
        return new Promise((resolve, reject) =>  {
            let url = self.UrlService.generate_single_elimination_pdf();
            let headers: Headers = new Headers();
            headers.append("accept", "application/pdf");
            self.HttpService.downloadPDF(url,data,headers).then((data:any)=>{
                // //console.log("-->",data.headers.get('Content-Disposition'));
                resolve(data);
            },(error)=>{
                // toastr['error']("Please try again later.", error.statusText);
                reject(error);
                //console.log('error at api : ',error);
            });
        });    
    }
    
}