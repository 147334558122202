import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var $;
@Injectable()
export class Mail {
    constructor( private http: HttpClient) {}
    SendMail(data){
        let self = this;
        //console.log(data);
        var host = ($(location).attr('hostname'));

        // let url = 'http://'+host+'/BracketPrint/public/api/contact-us';
        let url = 'https://'+host+'/public/api/contact-us';
        self.http.post(url, data).subscribe((response:any) =>  {	 			
            //console.log("response",response);
            if(response.success){
                $('.msg-alert > span').css({'padding-left':'10px','color':'green'}).html(response.message);
                $('.msg-alert').show();
                $('form[name=contactForm]')[0].reset();
                setTimeout(function(){
                    $('.msg-alert').hide();
                    $('.msg-alert span').html('');
                },3000);
            }else{
                $('.msg-alert span').css({'padding-left':'10px','color':'red'}).html(response.message);
                $('.msg-alert').show();
                setTimeout(function(){
                    $('.msg-alert').hide();
                    $('.msg-alert span').html('');
                },3000);
            }
        }, (error:any) =>  {
            //console.log("error",error);
            $('.msg-alert span').css({'padding-left':'10px','color':'red'}).html('sending failed');
            $('.msg-alert').show();
            setTimeout(function(){
                $('.msg-alert').hide();
                $('.msg-alert span').html('');
            },3000);
        });
        // var server  = self.email.server.connect({
        //     user:    "fake@bmacinfotech.com", 
        //     password:"nJf;@i29ih-Yb", 
        //     host:    "mail.bmacinfotech.com", 
        //     ssl:     true
        //  });
        // server.send({
        // text:"Contact", 
        // from:"fake@bmacinfotech.com", 
        // to:'support@bracketprint.com',
        // subject: "Welcome to my app",
        // // attachment: 
        // // [
        // //    {data:"<html>i <i>hope</i> this works!</html>", alternative:true},
        // //    {path:"pathtofile.zip", type:"application/zip", name:"renamed.zip"}
        // // ]
        // }, function(err, message) { 
        //     if(err)
        //     //console.log(err);
        //     else
        //     res.json({success: true, msg: 'sent'});
        // });
        
    }
    
}