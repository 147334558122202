export class globals {
	public constants = {
		http: 'http://',
		host: '192.168.0.5',
		port: ':1337',
		path: '/BracketPrint/public/api',
		generate_single_elimination_pdf : '/generate-single-elimination-pdf',
		generate_round_robin_pdf : '/generate-round-robin-pdf'
	};
}
