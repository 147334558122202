import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate() {
        // if (localStorage.getItem("isLoggedin") === null && localStorage.getItem("userdata") === null) {
        //     this.router.navigate(['/login']);
        //     return false;    
        // }else{
        //     if (localStorage.getItem('isLoggedin')) {
        //         if (localStorage.getItem('userdata')){
        //             var userData = JSON.parse(localStorage.getItem('userdata'));
        //             if(userData.usertype === 1){
        //                 return true;
        //             }else{
                        return true;
        //                 // this.router.navigate(['/dashboard']);
        //                 // return false;
        //             }
        //         }
        //     }else{
        //         this.router.navigate(['/login']);
        //         return false;
        //     }
        // }
    }
}
