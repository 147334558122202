import { Injectable }from '@angular/core'; 
import { UrlService,HttpService }from '../shared'; 

@Injectable()
export class AuthService {
    constructor(private HttpService:HttpService, private UrlService:UrlService) {}
    // login(userData:any) {
    //     let self = this; 
    //     return new Promise((resolve, reject) =>  {
    //         let url = self.UrlService.getLoginUrl(); 
    //         // let data:any =  {username:"test@pinnapt.com", password:"test@2018"}; 
    //         let headers: Headers = new Headers();
    //         headers.append("content-type", "application/json");
    //         self.HttpService.post(url, userData, headers).then((data) =>  {
    //             resolve(data);
    //         },(error)=>{
    //             reject(error);
    //         });
    //     });
    // }
}